import { useEffect, useRef, useState, useMemo } from 'react';
import {
  Typography,
  Container,
  Paper,
  TextField,
  Chip,
  Box,
  Divider,
  Breadcrumbs,
  Button,
  Switch,
  FormControlLabel,
  Avatar,
  IconButton
} from '@mui/material';
import Grid from '@mui/material/Grid2';
import axios from 'axios';
import { useForm, Controller } from 'react-hook-form';
import { LoadingButton } from '@mui/lab'
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import DeleteUserModal from '../../components/specific/modal/DeleteUser';
import { ProfileFormData } from '../../interfaces/SettingsProp';
import LockIcon from '@mui/icons-material/Lock';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import { styled } from '@mui/material/styles';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import { PIN_TYPES_OPTIONS } from '../../enums/MarkerOptions';

const url = process.env.REACT_APP_BASE_URL;

const CustomSwitch = styled(Switch)(({ theme }) => ({
  '& .MuiSwitch-switchBase.Mui-checked': {
    color: '#E9495A',
    '&:hover': {
      backgroundColor: theme.palette.action.hoverOpacity,
    },
  },
  '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
    backgroundColor: '#E9495A',
  },
}));

const ProfileSettings = () => {
  const { t } = useTranslation();
  const userId = sessionStorage.getItem('userId');
  const authToken = sessionStorage.getItem('authToken');
  const [firstName, setFirstName] = useState<string>('');
  const [lastName, setLastName] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [profilePicture, setProfilePicture] = useState<string | null>(null);

  const {
    control,
    handleSubmit,
    setValue,
  } = useForm<ProfileFormData>({
    defaultValues: {
      first_name: '',
      last_name: '',
      email: '',
      birth_date: new Date(),
      residence: '',
      public_residence: false,
      profile_picture: null,
      interested_to: [],
      phone_number: '',
      public_phone_number: false
    }
  });

  const headers = useMemo(() => ({
    'Authorization': `Bearer ${authToken}`
  }), [authToken]);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await axios.get(`${url}/users/auth/profile/`, { headers });
        const userData = response.data;
        setValue('first_name', userData.first_name);
        setValue('last_name', userData.last_name);
        setValue('email', userData.email);
        setValue('birth_date', userData.birth_date);
        setValue('residence', userData.residence);
        setValue('public_residence', userData.public_residence);
        setValue('interested_to', userData.interested_to);
        setValue('profile_picture', userData.profile_picture);
        setValue('phone_number', userData.phone_number);
        setValue('public_phone_number', userData.public_phone_number);
        setFirstName(userData.first_name);
        setLastName(userData.last_name);
        setProfilePicture(userData.profile_picture);
      } catch (error) {
        console.error('Errore nel recupero dei dati utente', error);
      }
    };

    fetchUserData();
  }, [setValue, userId, headers]);

  const handleAvatarClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleProfilePictureChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      try {
        if (profilePicture) {
          const deleteFormData = new FormData();
          deleteFormData.append('profile_picture', '');

          await axios.patch(
            `${url}/users/auth/profile/`, deleteFormData, {
            headers: {
              'Authorization': `Bearer ${authToken}`,
            },
          }
          );
          setProfilePicture(null);
          setValue('profile_picture', null);
          toast.success(t('toast:photo_deleted_success'), {
            position: 'bottom-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'colored'
          });
        }

        setValue('profile_picture', file);
        const reader = new FileReader();
        reader.onloadend = () => {
          setProfilePicture(reader.result as string);
        };
        reader.readAsDataURL(file);

        const formData = new FormData();
        formData.append('profile_picture', file);

        await axios.patch(`${url}/users/auth/profile/`, formData, {
          headers: {
            'Authorization': `Bearer ${authToken}`,
          },
        });

        window.dispatchEvent(new Event('profilePictureUpdated'));
        toast.success(t('toast:photo_success'), {
          position: 'bottom-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'colored'
        });
      } catch (error) {
        console.error('Errore nell\'aggiornamento della foto del profilo', error);
        toast.error(t('toast:photo_error'), {
          position: 'bottom-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'colored'
        });
      }
    }
  };

  const handleDeleteProfilePicture = async () => {
    setProfilePicture(null);
    setValue('profile_picture', null);

    try {
      const formData = new FormData();
      formData.append('profile_picture', '');

      await axios.patch(`${url}/users/auth/profile/`, formData, {
        headers: {
          'Authorization': `Bearer ${authToken}`,
        },
      });

      window.dispatchEvent(new Event('profilePictureUpdated'));
      toast.success(t('toast:photo_deleted_success'), {
        position: 'bottom-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored'
      });
    } catch (error) {
      console.error('Errore nella cancellazione della foto del profilo', error);
      toast.error(t('toast:photo_deleted_error'), {
        position: 'bottom-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored'
      });
    }
  };

  const onSubmit = async (data: any) => {
    try {
      setLoading(true);
      const formData = {
        residence: data.residence,
        public_residence: data.public_residence,
        phone_number: data.phone_number,
        public_phone_number: data.public_phone_number,
        interested_to: data.interested_to,
      };

      await axios.patch(`${url}/users/auth/profile/`, formData, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${authToken}`
        },
      });

      setLoading(false);
      toast.success(t('toast:settings_success'), {
        position: 'bottom-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored'
      });
    } catch (error) {
      console.error('Errore nell\'aggiornamento dei dati utente', error);
      toast.error(t('toast:settings_error'), {
        position: 'bottom-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored'
      });
    }
    setLoading(false);
  };

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  return (
    <div className="min-h-screen bg-gray-100">
      <Container maxWidth="lg" className="mt-8">
        <Breadcrumbs aria-label="breadcrumb" className="mb-4">
          <Typography className='text-text-tertiary text-xl' >
            {t('settings:breadcrumbs_title')}
          </Typography>
          <Typography className='text-text-primary font-bold text-md'>{t('settings:breadcrumbs_subtitle')}</Typography>
        </Breadcrumbs>
        <Divider className="my-6" />

        <Paper elevation={0} className="p-0">
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container>
              <Grid size={{ xs: 12, md: 9 }} className="bg-white p-8">
                <div className="w-full flex items-center mb-8 relative">
                  <div className="relative group">
                    <Avatar
                      src={profilePicture ?? undefined}
                      alt={`${firstName} ${lastName}`}
                      sx={{ width: 70, height: 70, cursor: 'pointer' }}
                      onClick={handleAvatarClick}
                    />
                    <div
                      className="w-full absolute inset-0 flex items-center justify-center opacity-0 hover:opacity-100 bg-black bg-opacity-5 rounded-full cursor-pointer"
                    >
                      <IconButton
                        size="large"
                        onClick={handleAvatarClick}
                        sx={{ width: '100%', height: '100%' }}
                      >
                        <AddIcon sx={{ color: '#E9495A' }} fontSize='large' />
                      </IconButton>
                    </div>
                    {profilePicture && (
                      <IconButton
                        size="small"
                        onClick={handleDeleteProfilePicture}
                        sx={{ position: 'absolute', top: -8, right: -8, backgroundColor: '#fff' }}
                      >
                        <DeleteIcon fontSize="small" sx={{ color: '#E9495A' }} />
                      </IconButton>
                    )}
                    <input
                      type="file"
                      accept="image/*"
                      ref={fileInputRef}
                      onChange={handleProfilePictureChange}
                      style={{ display: 'none' }}
                    />
                  </div>
                  <div className='ml-4'>
                    <Typography variant="h6" className="font-bold flex items-center">
                      {firstName} {lastName}
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                      {t('settings:settings_guide')}
                    </Typography>
                  </div>
                </div>

                <Divider className="my-6" />

                <Grid container spacing={3} className='w-full flex items-center justify-between'>

                  {/* First Name */}
                  <Grid size={{ xs: 12 }} className="w-full flex flex-col md:flex-row items-start md:items-center mb-1">
                    <Typography variant="body1" className="w-full md:w-1/2 font-bold text-sm mb-2 md:mb-0">{t('settings:first_name')}</Typography>
                    <Controller
                      name="first_name"
                      control={control}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          fullWidth
                          variant="outlined"
                          value={field.value ?? ''}
                          disabled
                          slotProps={{ input: { readOnly: true } }}
                        />
                      )}
                    />
                  </Grid>

                  {/* Last Name */}
                  <Grid size={{ xs: 12 }} className="w-full flex flex-col md:flex-row items-start md:items-center mb-1">
                    <Typography variant="body1" className="w-full md:w-1/2 font-bold text-sm mb-2 md:mb-0">{t('settings:last_name')}</Typography>
                    <Controller
                      name="last_name"
                      control={control}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          fullWidth
                          variant="outlined"
                          value={field.value ?? ''}
                          disabled
                          slotProps={{ input: { readOnly: true } }}
                        />
                      )}
                    />
                  </Grid>

                  {/* Email */}
                  <Grid size={{ xs: 12 }} className="w-full flex flex-col md:flex-row items-start md:items-center mb-1">
                    <Typography variant="body1" className="w-full md:w-1/2 font-bold text-sm mb-2 md:mb-0">{t('settings:email')}</Typography>
                    <Controller
                      name="email"
                      control={control}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          fullWidth
                          variant="outlined"
                          value={field.value ?? ''}
                          disabled
                          slotProps={{ input: { readOnly: true } }}
                        />
                      )}
                    />
                  </Grid>

                  {/* Birth Date */}
                  <Grid size={{ xs: 12 }} className="w-full flex flex-col md:flex-row items-start md:items-center mb-1">
                    <Typography variant="body1" className="w-full md:w-1/2 font-bold text-sm mb-2 md:mb-0">
                      {t('settings:birth_date')}
                    </Typography>
                    <Controller
                      name="birth_date"
                      control={control}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          value={field.value ?? ''}
                          type='date'
                          fullWidth
                          disabled
                          variant='outlined'
                          slotProps={{ input: { readOnly: true } }}
                        />
                      )}
                    />
                  </Grid>

                  <Typography variant="subtitle1" className="w-full font-regular text-text-secondary text-sm mt-6">
                    {t('settings:optional_info')}
                  </Typography>
                  {/* Phone Number */}
                  <Grid size={{ xs: 12 }} className="w-full flex flex-col md:flex-row items-start md:items-center mb-1">
                    <Typography variant="body1" className="w-full md:w-1/2 font-bold text-sm mb-2 md:mb-0">{t('settings:phone_number')}</Typography>
                    <div className="w-full flex items-center">
                      <Controller
                        name="phone_number"
                        control={control}
                        rules={{
                          pattern: {
                            value: /(^[03][0-9]{5,10}$)/,
                            message: t('welcome:phone_number_invalid'),
                          },
                        }}
                        render={({ field, fieldState }) => (
                          <TextField
                            {...field}
                            value={field.value ?? ''}
                            fullWidth
                            variant="outlined"
                            error={!!fieldState.error}
                            helperText={fieldState.error?.message}
                            sx={{
                              marginRight: { xs: '1rem', sm: '2rem' },
                              '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                  borderColor: '#E0E0E0',
                                },
                                '&:hover fieldset': {
                                  borderColor: '#E0E0E0',
                                },
                                '&.Mui-focused fieldset': {
                                  borderColor: '#E0E0E0',
                                },
                              },
                              '& .MuiInputLabel-root': {
                                color: '#000000',
                              },
                              '& .MuiInputLabel-root.Mui-focused': {
                                color: '#000000',
                              },
                              '& .MuiInputBase-input.Mui-disabled': {
                                color: '#000000',
                              },
                            }}
                          />
                        )}
                      />
                      <Controller
                        name="public_phone_number"
                        control={control}
                        render={({ field }) => (
                          <FormControlLabel
                            control={
                              <CustomSwitch
                                checked={field.value}
                                onChange={(e) => field.onChange(e.target.checked)}
                                color='error'
                              />
                            }
                            label={field.value ? <LockOpenIcon /> : <LockIcon />}
                          />
                        )}
                      />
                    </div>
                  </Grid>

                  {/* Residence */}
                  <Grid size={{ xs: 12 }} className="w-full flex flex-col md:flex-row items-start md:items-center mb-1">
                    <Typography variant="body1" className="w-full md:w-1/2 font-bold text-sm mb-2 md:mb-0">{t('settings:residence')}</Typography>
                    <div className="w-full flex items-center">
                      <Controller
                        name="residence"
                        control={control}
                        rules={{
                          required: 'La residenza è obbligatoria'
                        }}
                        render={({ field, fieldState }) => (
                          <TextField
                            {...field}
                            value={field.value ?? ''}
                            fullWidth
                            variant="outlined"
                            helperText={fieldState.error?.message}
                            sx={{
                              marginRight: { xs: '1rem', sm: '2rem' },
                              '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                  borderColor: '#E0E0E0',
                                },
                                '&:hover fieldset': {
                                  borderColor: '#E0E0E0',
                                },
                                '&.Mui-focused fieldset': {
                                  borderColor: '#E0E0E0',
                                },
                              },
                              '& .MuiInputLabel-root': {
                                color: '#000000',
                              },
                              '& .MuiInputLabel-root.Mui-focused': {
                                color: '#000000',
                              },
                              '& .MuiInputBase-input.Mui-disabled': {
                                color: '#000000',
                              },
                            }}
                          />
                        )}
                      />
                      <Controller
                        name="public_residence"
                        control={control}
                        render={({ field }) => (
                          <FormControlLabel
                            control={
                              <CustomSwitch
                                checked={field.value}
                                onChange={(e) => field.onChange(e.target.checked)}
                                color='error'
                              />
                            }
                            label={field.value ? <LockOpenIcon /> : <LockIcon />}
                          />
                        )}
                      />
                    </div>
                  </Grid>
                </Grid>

                <Divider className="my-6" />

                <Typography variant="subtitle1" className="w-full font-bold text-sm mb-4">
                  {t('settings:traveler_type')}
                </Typography>
                <Controller
                  name="interested_to"
                  control={control}
                  defaultValue={[]}
                  rules={{ required: t('welcome:interested_to_required') }}
                  render={({ field, fieldState }) => {
                    const { value, onChange } = field;
                    return (
                      <Box className="flex flex-wrap gap-4">
                        {PIN_TYPES_OPTIONS.map((option) => {
                          const isSelected = value.includes(option.value);
                          return (
                            <Chip
                              key={option.value}
                              label={
                                <span>
                                  {option.icon} {t(option.labelKey)}
                                </span>
                              }
                              onClick={() => {
                                const currentIndex = value.indexOf(option.value);
                                let newSelected = [...value];

                                if (currentIndex === -1) {
                                  newSelected.push(option.value);
                                } else {
                                  newSelected.splice(currentIndex, 1);
                                }
                                onChange(newSelected);
                              }}
                              variant={isSelected ? 'filled' : 'outlined'}
                              sx={{
                                ...(isSelected && {
                                  backgroundColor: '#EBEBEB',
                                  color: '#00000',
                                  '&:hover': {
                                    backgroundColor: '#cecece',
                                  },
                                }),
                              }}
                            />
                          );
                        })}
                        {fieldState.error && (
                          <Typography color="error" variant="body2">
                            {fieldState.error.message}
                          </Typography>
                        )}
                      </Box>
                    );
                  }}
                />
                <Divider className="my-6" />
                <div className='flex gap-6'>
                  <LoadingButton type='submit' variant="contained" loading={loading} className='bg-primary hover:bg-primary-hover'>
                    {t('common:save')}
                  </LoadingButton>
                </div>
                <Divider className="mt-6 mb-12" />
                <div>
                  <Typography variant='body1'>
                    {t('settings:delete_question')}
                  </Typography>
                  <Button
                    onClick={handleOpenModal}
                    sx={{ padding: 0, margin: '1rem auto', fontSize: { xs: '0.875rem', sm: '0.9rem' }, backgroundColor: 'trasparent', color: '#101828', fontWeight: 'bold', textTransform: 'initial', '&:hover': { backgroundColor: 'trasparent' } }}
                  >
                    {t('settings:delete_account')}
                  </Button>
                </div>
              </Grid>
            </Grid>
          </form>
        </Paper>
      </Container>
      <DeleteUserModal
        open={openModal}
        onClose={handleCloseModal}
      />
    </div >
  );
}

export default ProfileSettings