export enum AppRoutes {
    LOGIN = '/login',
    SIGNUP = '/signup',
    RESET_PASSWORD = '/reset-password',
    SET_NEW_PASSWORD = '/set-new-password/:userId/:authToken',
    PIN_MAP = '/',
    SETTINGS = '/settings',
    WELCOME = '/welcome',
    PINNERS = '/pinners',
    CONSULTANCY = '/consultancy',
    PROFILE = '/profile/:userId',
    ON_BOARDING = '/onboarding/:userId/:authToken',
    FEEDBACK = '/feedback',
    NOTIFICATIONS='/notifications',
    TERMS='/terms',
    PRIVACY='/privacy'
}