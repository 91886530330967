import React, { useEffect, useState } from 'react';
import { AppBar, Toolbar, IconButton, Menu, MenuItem, Avatar, Typography, Badge } from '@mui/material';
import { Menu as MenuIcon } from '@mui/icons-material';
import { Link, useNavigate } from 'react-router-dom';
import { AppRoutes } from '../../../enums/Approutes';
import LogoBlack from '../../../assets/images/logo-black.png'
import { useTranslation } from 'react-i18next';
import { UserInfo } from '../../../interfaces/ProfileInfo';
import axios from 'axios';
import LanguageSwitcher from '../../../i18n/LanguageSwitcher';
import SettingsIcon from '@mui/icons-material/Settings';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import MapIcon from '@mui/icons-material/Map';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import GroupIcon from '@mui/icons-material/Group';
import NotificationsIcon from '@mui/icons-material/Notifications';
import { Notification } from '../../../interfaces/Notification';

const url = process.env.REACT_APP_BASE_URL;

const Navbar = () => {
  const authToken = sessionStorage.getItem('authToken');
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [mobileMenuAnchorEl, setMobileMenuAnchorEl] = useState<null | HTMLElement>(null);
  const [unreadCount, setUnreadCount] = useState<number>(0);
  const [notificationRead, setNotificationRead] = useState<boolean>(false);
  const [userInfo, setUserInfo] = useState<UserInfo>({
    first_name: '',
    profile_picture: '',
  });
  const navigate = useNavigate();
  const { t } = useTranslation();

  useEffect(() => {
    const fetchUserData = async () => {
      const headers = {
        'Authorization': `Bearer ${authToken}`
      };
      try {
        const userResponse = await axios.get(
          `${url}/users/auth/profile/`, { headers }
        );
        const userData = userResponse.data;
        setUserInfo({
          first_name: userData.first_name,
          profile_picture: userData.profile_picture,
        });
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    };

    const fetchNotifications = async () => {
      try {
        const response = await axios.get(`${url}/auth/profile/notifications/`, {
          headers: {
            'Authorization': `Bearer ${authToken}`
          }
        });
        const notifications: Notification[] = response.data.results;
        const unread = notifications.filter((n) => n.read === null).length;
        setUnreadCount(unread);
        if (unread > 0) { setNotificationRead(false); }
      } catch (error) {
        console.error('Errore nel recupero delle notifiche:', error);
      }
    };

    fetchUserData();
    fetchNotifications();

    const handleProfilePictureUpdated = () => {
      fetchUserData();
    };

    window.addEventListener('profilePictureUpdated', handleProfilePictureUpdated);

    return () => {
      window.removeEventListener('profilePictureUpdated', handleProfilePictureUpdated);
    };
  }, [authToken]);

  const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setMobileMenuAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setMobileMenuAnchorEl(null);
  };

  const handleLogout = () => {
    navigate(AppRoutes.LOGIN);
  };

  const handleNotifications = () => {
    setNotificationRead(true);
    navigate(AppRoutes.NOTIFICATIONS);
  }

  const menuId = 'primary-search-account-menu';
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={Boolean(anchorEl)}
      onClose={handleMenuClose}
      slotProps={{
        paper: {
          sx: {
            marginTop: '54px',
            width: '148px'
          }
        }
      }}
    >
      <MenuItem onClick={handleMenuClose}>
        <Link to={AppRoutes.SETTINGS} style={{ display: 'flex', alignItems: 'center' }}>
          <SettingsIcon fontSize="small" sx={{ marginRight: '0.25rem' }} />
          <p>{t('navbar:settings')}</p>
        </Link>
      </MenuItem>
      <MenuItem onClick={() => { handleLogout(); handleMenuClose(); }} className="flex items-center">
        <ExitToAppIcon fontSize="small" sx={{ marginRight: '0.25rem' }} />
        {t('navbar:logout')}
      </MenuItem>
      <MenuItem>
        <LanguageSwitcher className='text-sm md:text-base' />
      </MenuItem>
      <div style={{ marginTop: '0.10rem' }}>
        <p style={{ marginLeft: '1.1rem', fontSize: '0.55rem' }}>v0.2.0</p>
      </div>
    </Menu>
  );

  const mobileMenuId = 'primary-search-account-menu-mobile';
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMenuAnchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={Boolean(mobileMenuAnchorEl)}
      onClose={handleMenuClose}
      slotProps={{
        paper: {
          sx: {
            marginTop: '54px',
            width: '148px'
          }
        }
      }}
    >
      <MenuItem onClick={handleMenuClose}>
        <Link to={AppRoutes.PIN_MAP} style={{ display: 'flex', alignItems: 'center' }}>
          <MapIcon fontSize="small" sx={{ marginRight: '0.25rem' }} />
          <p>{t('navbar:pinMap')}</p>
        </Link>
      </MenuItem>
      <MenuItem onClick={handleMenuClose}>
        <Link to={AppRoutes.PINNERS} style={{ display: 'flex', alignItems: 'center' }}>
          <GroupIcon fontSize="small" sx={{ marginRight: '0.25rem' }} />
          <p>Pinners</p>
        </Link>
      </MenuItem>
      <MenuItem onClick={handleMenuClose}>
        <Link to={AppRoutes.FEEDBACK} style={{ display: 'flex', alignItems: 'center' }}>
          <ThumbUpIcon fontSize="small" sx={{ marginRight: '0.25rem' }} />
          <p>Feedback</p>
        </Link>
      </MenuItem>
      <MenuItem onClick={handleMenuClose}>
        <Link to={AppRoutes.SETTINGS} style={{ display: 'flex', alignItems: 'center' }}>
          <SettingsIcon fontSize="small" sx={{ marginRight: '0.25rem' }} />
          <p>{t('navbar:settings')}</p>
        </Link>
      </MenuItem>
      <MenuItem onClick={() => { handleNotifications(); handleMenuClose(); }} style={{ display: 'flex', alignItems: 'center' }}>
        <Badge variant="dot" color="error" invisible={notificationRead || unreadCount === 0}>
          <NotificationsIcon fontSize='small' />
        </Badge>
        <Typography variant='body1' sx={{ marginLeft: '0.5rem' }}>Notifiche</Typography>
      </MenuItem>
      <MenuItem onClick={() => { handleLogout(); handleMenuClose(); }} style={{ display: 'flex', alignItems: 'center' }}>
        <ExitToAppIcon fontSize="small" sx={{ marginRight: '0.25rem' }} />
        <p>{t('navbar:logout')}</p>
      </MenuItem>
      <MenuItem>
        <LanguageSwitcher className='text-sm md:text-base' />
      </MenuItem>
      <div style={{ marginTop: '0.10rem' }}>
        <p style={{ marginLeft: '1.1rem', fontSize: '0.50rem' }}>v0.2.0</p>
      </div>
    </Menu>
  );

  return (
    <AppBar position="static" className="bg-white shadow-md">
      <Toolbar className="justify-between">
        <div className="flex items-center">
          <a href={AppRoutes.PIN_MAP}><img src={LogoBlack} className='w-24 h-auto object-cover' alt='Logo' /></a>
          <div className="hidden gap-8 ml-10 md:flex space-x-4">
            <Link to={AppRoutes.PIN_MAP} className="text-text-primary hover:text-primary transition duration-500 ease-in-out">
              {t('navbar:pinMap')}
            </Link>
            <Link to={AppRoutes.PINNERS} className="text-text-primary hover:text-primary transition duration-500 ease-in-out">
              Pinners
            </Link>
            <Link to={AppRoutes.FEEDBACK} className="text-text-primary hover:text-primary transition duration-500 ease-in-out">
              Feedback
            </Link>
          </div>
        </div>
        <div className="hidden md:flex items-center gap-2 mr-6">
          <IconButton color="error" onClick={handleNotifications}>
            <Badge variant="dot" color="success" invisible={notificationRead || unreadCount === 0}>
              <NotificationsIcon />
            </Badge>
          </IconButton>
          <IconButton
            size="large"
            edge="end"
            aria-label="account of current user"
            aria-controls={menuId}
            aria-haspopup="true"
            onClick={handleProfileMenuOpen}
            color="inherit"
            className="text-tertiary"
          >
            <Avatar alt={userInfo.first_name} src={userInfo.profile_picture || 'placeholder.svg'} />
          </IconButton>
        </div>
        <div className="md:hidden">
          <IconButton
            size="large"
            aria-label="show more"
            aria-controls={mobileMenuId}
            aria-haspopup="true"
            onClick={handleMobileMenuOpen}
            sx={{ color: 'text-text-tertiary' }}
          >
            <MenuIcon />
          </IconButton>
        </div>
      </Toolbar>
      {renderMobileMenu}
      {renderMenu}
    </AppBar>
  );
}

export default Navbar;
