import React, { useEffect, useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField, Box, FormControl, InputLabel, MenuItem, Select as MuiSelect, Checkbox, ListItemText, IconButton } from '@mui/material';
import { MarkerModalProp } from '../../../interfaces/MarkerModal';
import { useTranslation } from 'react-i18next';
import { useForm, Controller } from 'react-hook-form';
import mbxGeocoding, { GeocodeFeature } from '@mapbox/mapbox-sdk/services/geocoding';
import axios from 'axios';
import Select, { SingleValue } from 'react-select';
import { PlaceOption } from '../../../interfaces/Mapbox';
import { LoadingButton } from '@mui/lab';
import { toast } from 'react-toastify';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import CloseIcon from '@mui/icons-material/Close';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import 'dayjs/locale/en';
import 'dayjs/locale/it';
import { PIN_TYPES_OPTIONS, KNOWLEDGE_LEVEL_OPTIONS, TIMES_VISITED_OPTIONS } from '../../../enums/MarkerOptions';
import { MarkerData } from '../../../types/markerData';

const url = process.env.REACT_APP_BASE_URL;
const geocodingClient = mbxGeocoding({ accessToken: process.env.REACT_APP_MAPBOX_TOKEN! });

const MarkerModal: React.FC<MarkerModalProp> = ({ marker, open, onClose, onSave, isProfileMarker, setMarker }) => {
  const { t, i18n } = useTranslation();
  const [placeOptions, setPlaceOptions] = useState<PlaceOption[]>([]);
  const userId = sessionStorage.getItem('userId');
  const [loading, setLoading] = useState<boolean>(false);

  const { handleSubmit, control, reset, formState: { errors } } = useForm<MarkerData>({
    defaultValues: marker ?? {}
  });

  useEffect(() => {
    if (marker) {
      reset(marker);
    }
  }, [marker, reset]);

  useEffect(() => {
    dayjs.locale(i18n.language);
  }, [i18n.language]);

  const fetchPlaceSuggestions = async (inputValue: string) => {
    try {
      const response = await geocodingClient
        .forwardGeocode({
          query: inputValue,
          autocomplete: true,
          limit: 5,
          language: [i18n.language]
        })
        .send();

      const fetchedOptions = response.body.features.map((feature: GeocodeFeature) => ({
        label: feature.place_name,
        value: feature.place_name,
        center: feature.center as [number, number]
      }));

      setPlaceOptions(fetchedOptions);

    } catch (error) {
      console.error('Error fetching place suggestions:', error);
    }
  };

  if (!marker) return null;

  const onSubmit = async (data: MarkerData) => {
    try {
      setLoading(true);
      const authToken = sessionStorage.getItem('authToken');
      const headers = {
        'Authorization': `Bearer ${authToken}`
      };

      let userInputName = data.name;
      let englishName = userInputName;
      let correctCoordinates: [number, number] = [marker.longitude, marker.latitude];
      try {
        const enResponse = await geocodingClient
          .forwardGeocode({
            query: userInputName,
            limit: 1,
            language: ['en']
          })
          .send();

        if (enResponse.body.features && enResponse.body.features.length > 0) {
          const bestFeature = enResponse.body.features[0];
          englishName = bestFeature.place_name;
          correctCoordinates = bestFeature.geometry.coordinates as [number, number];
        }
      } catch (reversError) {
        console.error('Error in reverse function:', reversError);
      }

      const markerData = {
        ...data,
        name: englishName,
        user: parseInt(userId!),
        longitude: correctCoordinates[0],
        latitude: correctCoordinates[1],
        date_last_visit: dayjs(data.date_last_visit, 'MM-YYYY').format('YYYY-MM')
      };

      await axios.post(`${url}/pins/`, markerData, { headers });

      onSave(markerData);
      onClose();
      reset();
      setLoading(false);

      toast.success(t('toast:save_pin_success'), {
        position: 'bottom-left',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored'
      });
    } catch (error) {
      console.error('Error submitting data:', error);
      toast.error(t('toast:save_pin_error'), {
        position: 'bottom-left',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored'
      });
      setLoading(false);
    }
  };

  const handleClose = () => {
    reset();
    onClose();
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={i18n.language}>
      <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
        <DialogTitle>
          {t('pinMap:newPin')} <span className='text-primary font-bold'>Pin</span>
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>

            <FormControl fullWidth margin="normal" error={!!errors.name}>
              <Controller
                name="name"
                control={control}
                rules={{
                  required: t('pinMap:placeRequired'),
                }}
                render={({ field }) => (
                  <>
                    <Select<PlaceOption>
                      placeholder={t('pinMap:place')}
                      inputId="place-select"
                      isClearable
                      options={placeOptions}
                      required
                      value={placeOptions.find(option => option.value === field.value) || null}
                      onInputChange={(inputValue) => {
                        if (inputValue) {
                          fetchPlaceSuggestions(inputValue);
                        }
                      }}
                      onChange={(selectedOption: SingleValue<PlaceOption>) => {
                        if (selectedOption) {
                          field.onChange(selectedOption.value);
                          if (selectedOption.center) {
                            const [lng, lat] = selectedOption.center;
                            setMarker({
                              ...marker,
                              name: selectedOption.value,
                              latitude: lat,
                              longitude: lng
                            });
                          } else {
                            setMarker({
                              ...marker,
                              name: selectedOption.value
                            });
                          }
                        } else {
                          field.onChange('');
                          setMarker({
                            ...marker,
                            name: '',
                            latitude: 0,
                            longitude: 0
                          });
                        }
                      }}
                      styles={{
                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                        option: (provided, state) => ({
                          ...provided,
                          backgroundColor: state.isSelected
                            ? '#E0E0E0'
                            : state.isFocused
                              ? '#E0E0E0'
                              : provided.backgroundColor,
                          color: state.isSelected
                            ? 'black'
                            : state.isFocused
                              ? 'black'
                              : provided.color,
                        }),
                        control: (provided, state) => ({
                          ...provided,
                          borderColor: state.isFocused ? '#E0E0E0' : provided.borderColor,
                          boxShadow: state.isFocused ? '0 0 0 1px #E0E0E0' : provided.boxShadow,
                          '&:hover': {
                            borderColor: state.isFocused ? '#E0E0E0' : provided.borderColor,
                          },
                          height: '3.5rem',
                        }),
                        singleValue: (provided) => ({
                          ...provided,
                          color: 'black',
                        }),
                      }}
                      menuPortalTarget={document.body}
                    />
                    {(errors.name) && (
                      <span
                        style={{
                          color: '#E9495A',
                          fontSize: '0.75rem',
                          paddingLeft: '0.7rem',
                          paddingTop: '0.1rem',
                        }}
                      >
                        {String(errors.name?.message)}
                      </span>
                    )}
                  </>
                )}
              />
            </FormControl>


            <FormControl fullWidth margin="normal" error={!!errors.pin_type}>
              <InputLabel
                sx={{
                  color: '#000000',
                  '&.Mui-focused': {
                    color: '#000000',
                  },
                }}
              >
                {t('pinMap:travelType')}
              </InputLabel>
              <Controller
                name="pin_type"
                control={control}
                rules={{
                  required: t('pinMap:travelTypeRequired'),
                  validate: (value: number[]) =>
                    value && value.length >= 1 && value.length <= 3
                      ? true
                      : t('pinMap:travelTypeValidation')
                }}
                render={({ field }) => {
                  const selectedValues = field.value || [];
                  const maxSelectionsReached = selectedValues.length >= 3;
                  return (
                    <MuiSelect
                      {...field}
                      label={t('pinMap:travelType')}
                      required
                      multiple
                      renderValue={(selected) => {
                        const labels = PIN_TYPES_OPTIONS
                          .filter(opt => selected.includes(opt.value))
                          .map(opt => `${opt.icon} ${t(opt.labelKey)}`)
                          .join(', ');
                        return labels;
                      }}
                      onChange={(event) => {
                        const newArr = event.target.value as number[];
                        if (newArr.length <= 3) {
                          field.onChange(newArr);
                        }
                      }}
                      sx={{
                        '& .MuiOutlinedInput-notchedOutline': {
                          borderColor: '#E0E0E0',
                        },
                        '&:hover .MuiOutlinedInput-notchedOutline': {
                          borderColor: '#E0E0E0',
                        },
                        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                          borderColor: '#E0E0E0',
                        },
                        '& .MuiSelect-select': {
                          color: '#000000',
                        },
                        '& .MuiSvgIcon-root': {
                          color: '#000000',
                        },
                      }}
                    >
                      {PIN_TYPES_OPTIONS.map((option) => {
                        const isSelected = selectedValues.includes(option.value);
                        const isDisabled = !isSelected && maxSelectionsReached;
                        return (
                          <MenuItem key={option.value} value={option.value} disabled={isDisabled}>
                            <Checkbox checked={isSelected} color='error' />
                            <ListItemText primary={<span>{option.icon} {t(option.labelKey)}</span>} />
                          </MenuItem>
                        );
                      })}
                    </MuiSelect>
                  );
                }}
              />
              {errors.pin_type && (
                <span style={{ color: '#E9495A', fontSize: '0.75rem', paddingLeft: '0.7rem', paddingTop: '0.1rem' }}>{String(errors.pin_type.message)}</span>
              )}
            </FormControl>

            <FormControl fullWidth margin='normal' error={!!errors.times_visited}>
              <InputLabel
                sx={{
                  color: '#000000',
                  '&.Mui-focused': {
                    color: '#000000',
                  },
                }}
              >
                {t('pinMap:timeVisited')}
              </InputLabel>
              <Controller
                name="times_visited"
                control={control}
                rules={{
                  required: t('pinMap:timeVisitedRequired')
                }}
                render={({ field }) => (
                  <MuiSelect
                    {...field}
                    value={field.value}
                    label={t('pinMap:timeVisited')}
                    required
                    sx={{
                      '& .MuiOutlinedInput-notchedOutline': {
                        borderColor: '#E0E0E0',
                      },
                      '&:hover .MuiOutlinedInput-notchedOutline': {
                        borderColor: '#E0E0E0',
                      },
                      '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                        borderColor: '#E0E0E0',
                      },
                      '& .MuiSelect-select': {
                        color: '#000000',
                      },
                      '& .MuiSvgIcon-root': {
                        color: '#000000',
                      },
                    }}
                  >
                    {TIMES_VISITED_OPTIONS.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {t(option.labelKey)}
                      </MenuItem>
                    ))}
                  </MuiSelect>
                )}
              />
              {errors.times_visited && (
                <span style={{ color: '#E9495A', fontSize: '0.75rem', paddingLeft: '0.7rem', paddingTop: '0.1rem' }}>{String(errors.times_visited.message)}</span>
              )}
            </FormControl>

            <FormControl fullWidth margin="normal">
              <InputLabel
                sx={{
                  color: '#000000',
                  '&.Mui-focused': {
                    color: '#000000',
                  },
                }}
              >
                {t('pinMap:knowledge')}
              </InputLabel>
              <Controller
                name="knowledge_level"
                control={control}
                render={({ field }) => (
                  <MuiSelect
                    {...field}
                    value={field.value}
                    label={t('pinMap:knowledge')}
                    required
                    sx={{
                      '& .MuiOutlinedInput-notchedOutline': {
                        borderColor: '#E0E0E0',
                      },
                      '&:hover .MuiOutlinedInput-notchedOutline': {
                        borderColor: '#E0E0E0',
                      },
                      '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                        borderColor: '#E0E0E0',
                      },
                      '& .MuiSelect-select': {
                        color: '#000000',
                      },
                      '& .MuiSvgIcon-root': {
                        color: '#000000',
                      },
                    }}
                  >
                    {KNOWLEDGE_LEVEL_OPTIONS.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {t(option.labelKey)}
                      </MenuItem>
                    ))}
                  </MuiSelect>
                )}
              />
            </FormControl>

            <FormControl fullWidth margin='normal' error={!!errors.date_last_visit}>
              <Controller
                name="date_last_visit"
                control={control}
                rules={{
                  required: t('pinMap:visitDateRequired')
                }}
                render={({ field }) => (
                  <DatePicker
                    {...field}
                    label={t('pinMap:visitDate')}
                    views={['year', 'month']}
                    format='MM/YYYY'
                    value={field.value ? dayjs(field.value, 'MM-YYYY') : null}
                    onChange={(newValue) => {
                      field.onChange(newValue ? newValue.format('MM-YYYY') : '');
                    }}
                    slotProps={{
                      textField: {
                        error: !!errors.date_last_visit,
                        helperText: errors.date_last_visit ? errors.date_last_visit.message : '',
                        sx: {
                          '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                              borderColor: '#E0E0E0',
                            },
                            '&:hover fieldset': {
                              borderColor: '#E0E0E0',
                            },
                            '&.Mui-focused fieldset': {
                              borderColor: '#E0E0E0',
                            },
                          },
                          '& .MuiInputLabel-root': {
                            color: '#000000',
                          },
                          '& .MuiInputLabel-root.Mui-focused': {
                            color: '#000000',
                          },
                          '& .MuiInputBase-input.Mui-disabled': {
                            color: '#000000',
                          },
                        },
                      },
                    }}
                  />
                )}
              />
            </FormControl>

            {!isProfileMarker && (
              <>
                <Controller
                  name="photo_link"
                  control={control}
                  rules={{ maxLength: 300 }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      type="url"
                      label={
                        <span style={{ display: 'flex', alignItems: 'center' }}>
                          {t('pinMap:photoLink')}
                          <LockOutlinedIcon style={{ marginLeft: 6 }} />
                        </span>
                      }
                      value={field.value}
                      onChange={(e) => {
                        const value = e.target.value.slice(0, 300);
                        field.onChange(value);
                      }}
                      variant="outlined"
                      fullWidth
                      margin="normal"
                      helperText={`${field.value.length}/300`}
                      slotProps={{ inputLabel: { shrink: true } }}
                      sx={{
                        '& .MuiOutlinedInput-root': {
                          '& fieldset': {
                            borderColor: '#E0E0E0',
                          },
                          '&:hover fieldset': {
                            borderColor: '#E0E0E0',
                          },
                          '&.Mui-focused fieldset': {
                            borderColor: '#E0E0E0',
                          },
                        },
                        '& .MuiInputLabel-root': {
                          color: '#000000',
                        },
                        '& .MuiInputLabel-root.Mui-focused': {
                          color: '#000000',
                        },
                        '& .MuiInputBase-input.Mui-disabled': {
                          color: '#000000',
                        },
                      }}
                    />
                  )}
                />

                <Controller
                  name="content"
                  control={control}
                  rules={{ maxLength: 1000 }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label={
                        <span style={{ display: 'flex', alignItems: 'center' }}>
                          {t('pinMap:notes')}
                          <LockOutlinedIcon style={{ marginLeft: 6 }} />
                        </span>
                      }
                      value={field.value}
                      onChange={(e) => {
                        const value = e.target.value.slice(0, 1000);
                        field.onChange(value);
                      }}
                      variant="outlined"
                      fullWidth
                      multiline
                      rows={4}
                      margin="normal"
                      helperText={`${field.value.length}/1000`}
                      slotProps={{
                        inputLabel: { shrink: true }
                      }}
                      sx={{
                        '& .MuiOutlinedInput-root': {
                          '& fieldset': {
                            borderColor: '#E0E0E0',
                          },
                          '&:hover fieldset': {
                            borderColor: '#E0E0E0',
                          },
                          '&.Mui-focused fieldset': {
                            borderColor: '#E0E0E0',
                          },
                        },
                        '& .MuiInputLabel-root': {
                          color: '#000000',
                        },
                        '& .MuiInputLabel-root.Mui-focused': {
                          color: '#000000',
                        },
                        '& .MuiInputBase-input.Mui-disabled': {
                          color: '#000000',
                        },
                      }}
                    />
                  )}
                />
              </>
            )}
          </Box>
        </DialogContent>
        <DialogActions className='flex justify-center items-center' sx={{ paddingRight: { xs: 'none', sm: '1.5rem' } }}>
          <Button
            onClick={handleClose}
            sx={{ margin: '1rem', fontSize: { xs: '0.875rem', sm: '1rem' }, backgroundColor: 'trasparent', color: '#475467', border: '1px solid #667085' }}
            fullWidth
          >
            {t('common:close')}
          </Button>
          <LoadingButton
            onClick={handleSubmit(onSubmit)}
            loading={loading}
            sx={{ margin: '1rem', fontSize: { xs: '0.875rem', sm: '1rem' }, backgroundColor: '#E9495A', color: '#FFFFFF', '&:hover': { backgroundColor: '#E6263B' } }}
            fullWidth
          >
            {t('common:save')}
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </LocalizationProvider>
  );
};

export default MarkerModal
