import React, { useEffect, useState } from "react";
import {
  TextField,
  Container,
  Box,
  Typography,
  InputAdornment,
  IconButton,
} from "@mui/material";
import { RegisterFormType } from "../../../types/register";
import { useForm, SubmitHandler, Controller } from "react-hook-form";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import { Link, useNavigate } from "react-router-dom";
import { AppRoutes } from "../../../enums/Approutes";
import { useTranslation, Trans } from "react-i18next";
import axios from "axios";
import { LoadingButton } from "@mui/lab";
import { toast } from "react-toastify";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import GoogleButton from "../button/GoogleButton";

const url = process.env.REACT_APP_BASE_URL;

const RegisterForm = () => {
  const { t } = useTranslation();
  const {
    control,
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<RegisterFormType>();
  const [passwordCriteria, setPasswordCriteria] = useState({
    length: false,
    specialChar: false,
  });
  const [loading, setLoading] = useState<boolean>(false);
  const navigate = useNavigate();
  const password = watch("password");
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  useEffect(() => {
    if (password) {
      setPasswordCriteria({
        length: password.length >= 8,
        specialChar: /[!@#$%^&*(),.?":{}|<>]/.test(password),
      });
    }
  }, [password]);

  const onError = () => {
    toast.error(t("toast:register_error"), {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });
  };

  const onSubmit: SubmitHandler<RegisterFormType> = async (data) => {
    const createContactOptions = {
      method: "POST",
      headers: {
        accept: "application/json",
        "content-type": "application/json",
        "api-key":
          "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",
      },
      body: JSON.stringify({
        email: data.email,
        emailBlacklisted: false,
        smsBlacklisted: false,
        listIds: [12],
        updateEnabled: false,
      }),
    };

    const updateContactOptions = {
      method: "POST",
      headers: {
        accept: "application/json",
        "content-type": "application/json",
        "api-key":
          "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",
      },
      body: JSON.stringify({ emails: [data.email] }),
    };

    try {
      setLoading(true);
      const response = await axios.post(`${url}/users/register/`, data);
      if (response.data.token) {
        console.log(response.data);
        const user = response.data.user;
        const userId = user.id;
        const accessToken = response.data.token;
        sessionStorage.setItem("userId", userId.toString());
        sessionStorage.setItem("authToken", accessToken.access);

        fetch("https://api.brevo.com/v3/contacts", createContactOptions)
          .then((response) => response.json())
          .then((response) => {
            console.log(response);
            if (response.code === "duplicate_parameter") {
              fetch(
                "https://api.brevo.com/v3/contacts/lists/12/contacts/add",
                updateContactOptions
              )
                .then((response) => response.json())
                .then((response) => {
                  console.log("update succesful", response);
                  navigate(AppRoutes.WELCOME, { state: { email: user.email } });
                })
                .catch((err) => console.error("error update", err));
            } else {
              navigate(AppRoutes.WELCOME, { state: { email: user.email } });
            }
          })
          .catch((err) => console.error(err));
      }
      setLoading(false);
    } catch (error) {
      console.error("errore", error);
      onError();
      setLoading(false);
    }
  };

  return (
    <Container maxWidth="sm">
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          boxShadow: "none",
          width: "100%",
          padding: { xs: "0 1rem", sm: 0 },
        }}
      >
        <form onSubmit={handleSubmit(onSubmit)} className="w-full">
          <TextField
            label="Email"
            variant="outlined"
            fullWidth
            margin="normal"
            {...register("email", {
              required: t("register:emailRequired"),
              pattern: {
                value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                message: t("register:invalidEmail"),
              },
            })}
            error={!!errors.email}
            helperText={errors.email ? errors.email.message : ""}
            slotProps={{
              inputLabel: { shrink: true },
            }}
            sx={{
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  borderColor: "#E0E0E0",
                },
                "&:hover fieldset": {
                  borderColor: "#E0E0E0",
                },
                "&.Mui-focused fieldset": {
                  borderColor: "#E0E0E0",
                },
              },
              "& .MuiInputLabel-root": {
                color: "#000000",
              },
              "& .MuiInputLabel-root.Mui-focused": {
                color: "#000000",
              },
              "& .MuiInputBase-input.Mui-disabled": {
                color: "#000000",
              },
            }}
          />

          <Controller
            name="password"
            control={control}
            defaultValue=""
            rules={{
              required: {
                value: true,
                message: t("register:passwordRequired"),
              },
              minLength: { value: 8, message: t("register:passwordMinLength") },
              pattern: {
                value: /[!@#$%^&*(),.?":{}|<>]/,
                message: t("register:passwordSpecialChar"),
              },
            }}
            render={({ field, fieldState: { error } }) => (
              <TextField
                {...field}
                type={showPassword ? "text" : "password"}
                label="Password"
                variant="outlined"
                fullWidth
                margin="normal"
                error={!!error}
                helperText={error?.message}
                slotProps={{
                  inputLabel: { shrink: true },
                  input: {
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={handleClickShowPassword}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  },
                }}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderColor: "#E0E0E0",
                    },
                    "&:hover fieldset": {
                      borderColor: "#E0E0E0",
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: "#E0E0E0",
                    },
                  },
                  "& .MuiInputLabel-root": {
                    color: "#000000",
                  },
                  "& .MuiInputLabel-root.Mui-focused": {
                    color: "#000000",
                  },
                  "& .MuiInputBase-input.Mui-disabled": {
                    color: "#000000",
                  },
                }}
              />
            )}
          />
          <Box className="mt-2 mb-4">
            <Typography variant="body2" className="flex items-center">
              <CheckCircleRoundedIcon
                color={passwordCriteria.length ? "success" : "disabled"}
                className="mr-2"
              />
              {t("register:passwordMinLength")}
            </Typography>
            <Typography variant="body2" className="flex items-center mt-1">
              <CheckCircleRoundedIcon
                color={passwordCriteria.specialChar ? "success" : "disabled"}
                className="mr-2"
              />
              {t("register:passwordSpecialChar")}
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              gap: 0,
              width: "100%",
              margin: "1.2rem auto",
            }}
          >
            <LoadingButton
              type="submit"
              variant="contained"
              sx={{
                fontSize: { xs: "0.875rem", sm: "1rem" }
              }}
              fullWidth
              className="bg-primary hover:bg-primary-hover"
              loading={loading}
            >
              {t("register:register")}
            </LoadingButton>

            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: 1,
                width: "100%",
                margin: "1rem 0rem 0rem 0rem",
              }}
            >
              <Typography
                variant="body2"
                sx={{ fontSize: { xs: "0.70rem", sm: "0.78rem" } }}
              >
                <Trans
                  i18nKey="register:signupAgreement"
                  components={{
                    terms: <Link to={AppRoutes.TERMS} target="_blank" rel="noopener noreferrer" className="text-secondary underline" />,
                    privacy: <Link to={AppRoutes.PRIVACY} target="_blank" rel="noopener noreferrer" className="text-secondary underline" />
                  }}
                />
              </Typography>
            </Box>

            {/* OR LINE UI */}
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                width: '100%',
                margin: '0.6rem 0rem 1rem 0rem',
              }}
            >
              <Box
                sx={{
                  flex: 1,
                  height: '1px',
                  backgroundColor: '#E0E0E0',
                }}
              />
              <Box
                sx={{
                  px: 2,
                  fontSize: '0.875rem',
                  fontWeight: 'bold',
                  color: '#757575',
                }}
              >
                {t('login:or')}
              </Box>
              <Box
                sx={{
                  flex: 1,
                  height: '1px',
                  backgroundColor: '#E0E0E0',
                }}
              />
            </Box>

            {/* GOOGLE BUTTON */}
            <Box
              sx={{
                width: "100%",
                height: 'auto',
                textAlign: "center"
              }}
            >
              <GoogleButton text={t('register:googleRegister')} />
            </Box>
          </Box>
        </form>
      </Box>
    </Container>
  );
};

export default RegisterForm;
