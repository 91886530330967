interface EnumOption {
    value: number;
    labelKey: string;
    icon?: string;
}

export const PIN_TYPES_OPTIONS: EnumOption[] = [
    { value: 0, icon: "🎒", labelKey: 'trip_types:Avventuriero' },
    { value: 1, icon: "🏃‍♂️", labelKey: 'trip_types:Sportivo' },
    { value: 2, icon: "🏡", labelKey: 'trip_types:Famiglia' },
    { value: 3, icon: "👨‍👩‍👧‍👦", labelKey: 'trip_types:Famiglia_con_Bambini' },
    { value: 4, icon: "🏖️", labelKey: 'trip_types:Relax' },
    { value: 5, icon: "🍽️", labelKey: 'trip_types:Food' },
    { value: 6, icon: "🌍", labelKey: 'trip_types:Nomade' },
    { value: 7, icon: "💼", labelKey: 'trip_types:Business' },
    { value: 8, icon: "🎨", labelKey: 'trip_types:Culturale' },
    { value: 9, icon: "📸", labelKey: 'trip_types:Influencer' },
    { value: 10, icon: "🎉", labelKey: 'trip_types:Divertimento' },
];

export const TIMES_VISITED_OPTIONS: EnumOption[] = [
    { value: 0, labelKey: 'times_visited:0' },
    { value: 1, labelKey: 'times_visited:1' },
    { value: 2, labelKey: 'times_visited:2' },
    { value: 3, labelKey: 'times_visited:3' },
    { value: 4, labelKey: 'times_visited:livedHere' },
];

export const KNOWLEDGE_LEVEL_OPTIONS: EnumOption[] = [
    { value: 0, labelKey: 'knoledge_level:little' },
    { value: 1, labelKey: 'knoledge_level:fairly' },
    { value: 2, labelKey: 'knoledge_level:very' },
    { value: 3, labelKey: 'knoledge_level:pro' },
];