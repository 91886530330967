import React, { useState, useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography, Box, Chip, IconButton } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { InfoMarkerModalProps } from '../../../interfaces/InfoMarker';
import CloseIcon from '@mui/icons-material/Close';
import mbxGeocoding from '@mapbox/mapbox-sdk/services/geocoding';
import { KNOWLEDGE_LEVEL_OPTIONS, PIN_TYPES_OPTIONS, TIMES_VISITED_OPTIONS } from '../../../enums/MarkerOptions';

const geocodingClient = mbxGeocoding({ accessToken: process.env.REACT_APP_MAPBOX_TOKEN! });

const InfoMarkerModal: React.FC<InfoMarkerModalProps> = ({ marker, open, onClose, onDelete, onEdit, hidePrivateContent }) => {
    const { t, i18n } = useTranslation();
    const [localizedPlaceName, setLocalizedPlaceName] = useState(marker?.name || '');

    useEffect(() => {
        if (!marker?.name) return;
        if (i18n.language === 'en') {
            setLocalizedPlaceName(marker.name);
            return;
        }
        let cancelRequest = false;
        geocodingClient
            .forwardGeocode({
                query: marker.name,
                limit: 1,
                language: [i18n.language]
            })
            .send()
            .then((res) => {
                if (!cancelRequest && res.body.features.length > 0) {
                    setLocalizedPlaceName(res.body.features[0].place_name);
                } else {
                    setLocalizedPlaceName(marker.name);
                }
            })
            .catch(() => {
                setLocalizedPlaceName(marker.name);
            });

        return () => {
            cancelRequest = true;
        };
    }, [marker?.name, i18n.language]);

    if (!marker) return null;

    const pinTypeValues = marker.pin_type || [];
    const timesVisitedLabel = TIMES_VISITED_OPTIONS.find(o => o.value === marker.times_visited);
    const knowledgeLevelLabel = KNOWLEDGE_LEVEL_OPTIONS.find(o => o.value === marker.knowledge_level);

    return (
        <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
            <DialogTitle>
                {t('pinMap:viewPin')} <span style={{ color: '#E9495A', fontWeight: 'bold' }}>Pin</span>
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent sx={{ maxWidth: '100%', overflowX: 'hidden' }}>
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 4 }}>
                    {/* Place */}
                    <div className='w-full text-left'>
                        <Typography variant="body2" sx={{ marginBottom: '1rem', fontWeight: 'bold', color: '#101828' }}>{t('pinMap:place')}</Typography>
                        <Typography variant="body1" sx={{ color: '#667085', fontWeight: 'medium' }}>{localizedPlaceName}
                        </Typography>
                    </div>

                    {/* Travel Types */}
                    <div className='w-full text-left'>
                        <Typography variant="body2" sx={{ marginBottom: '1rem', fontWeight: 'bold', color: '#101828' }}>{t('pinMap:travelType')}</Typography>
                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
                            {pinTypeValues.map((typeValue, index) => {
                                const pin = PIN_TYPES_OPTIONS.find(p => p.value === typeValue);
                                if (!pin) return null;
                                return (
                                    <Chip key={index} sx={{ backgroundColor: 'F9FAFB' }} label={
                                        <span>
                                            {pin.icon} {t(pin.labelKey)}
                                        </span>
                                    }
                                    />
                                );
                            })}
                        </Box>
                    </div>

                    {/* Times Visited */}
                    <div className='w-full text-left'>
                        <Typography variant="body2" sx={{ marginBottom: '1rem', fontWeight: 'bold', color: '#101828' }}>{t('pinMap:timeVisited')}</Typography>
                        <Typography variant="body1" sx={{ color: '#667085', fontWeight: 'medium' }}>
                            {timesVisitedLabel ? t(timesVisitedLabel.labelKey) : ''}
                        </Typography>
                    </div>

                    {/* Knowledge Level */}
                    <div className='w-full text-left'>
                        <Typography variant="body2" sx={{ marginBottom: '1rem', fontWeight: 'bold', color: '#101828' }}>{t('pinMap:knowledge')}</Typography>
                        <Typography variant="body1" sx={{ color: '#667085', fontWeight: 'medium' }}>
                            {knowledgeLevelLabel ? t(knowledgeLevelLabel.labelKey) : '-'}
                        </Typography>
                    </div>

                    {/* Date Last Visit */}
                    <div className='w-full text-left'>
                        <Typography variant="body2" sx={{ marginBottom: '1rem', fontWeight: 'bold', color: '#101828' }}>{t('pinMap:visitDate')}</Typography>
                        <Typography variant="body1" sx={{ color: '#667085', fontWeight: 'medium' }}>{String(marker.date_last_visit)}</Typography>
                    </div>

                    {/* Photo Link */}
                    {!hidePrivateContent && marker.photo_link && (
                        <div className='w-full text-left'>
                            <Typography variant="body2" sx={{ marginBottom: '1rem', fontWeight: 'bold', color: '#101828' }}>{t('pinMap:photoLink')}</Typography>
                            <Typography variant="body1" sx={{ width: '100%', color: '#667085', fontWeight: 'medium', overflowWrap: 'break-word' }}>{marker.photo_link}</Typography>
                        </div>
                    )}

                    {/* Content */}
                    {!hidePrivateContent && marker.content && (
                        <div className='w-full text-left'>
                            <Typography variant="body2" sx={{ marginBottom: '1rem', fontWeight: 'bold', color: '#101828' }}>{t('pinMap:notes')}</Typography>
                            <Typography variant="body1" sx={{ width: '100%', color: '#667085', fontWeight: 'medium', overflowWrap: 'break-word' }}>{marker.content}</Typography>
                        </div>
                    )}
                </Box>
            </DialogContent>
            <DialogActions className='flex justify-center items-center' sx={{ paddingRight: { xs: 'none', sm: onDelete ? '1.5rem' : 'none' } }}>
                {(onDelete && onEdit) ? (
                    <>
                        <Button
                            onClick={onDelete}
                            sx={{ margin: '1rem', fontSize: { xs: '0.875rem', sm: '1rem' }, backgroundColor: 'transparent', color: '#475467', border: '1px solid #667085' }}
                            fullWidth
                        >
                            {t('common:delete')}
                        </Button>
                        <Button
                            onClick={onEdit}
                            sx={{ margin: '1rem', fontSize: { xs: '0.875rem', sm: '1rem' }, backgroundColor: '#E9495A', color: '#FFFFFF', '&:hover': { backgroundColor: '#E6263B' } }}
                            fullWidth
                        >
                            {t('common:edit')}
                        </Button>
                    </>
                ) : (
                    <Button
                        onClick={onClose}
                        sx={{ margin: '1rem', fontSize: { xs: '0.875rem', sm: '1rem' }, backgroundColor: 'transparent', color: '#475467', border: '1px solid #667085' }}
                        fullWidth
                    >
                        {t('common:close')}
                    </Button>
                )}
            </DialogActions>
        </Dialog>
    );
};

export default InfoMarkerModal;
