import React, { useEffect, useState } from 'react';
import { Typography, TextField, Button, Avatar, Chip, Select, MenuItem } from '@mui/material';
import { LoadingButton } from '@mui/lab'
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { PinnersData } from '../../interfaces/Pinners';
import MapIcon from '@mui/icons-material/Map';
import { AppRoutes } from '../../enums/Approutes';
import LoaderGif from '../../assets/gifs/loader.gif'
import { EmptyState } from '../../assets/icons/Index';
import TotPin from '../../assets/images/totPin.png';
import TotPinPro from '../../assets/images/totPinPro.png';
import { PIN_TYPES_OPTIONS } from '../../enums/MarkerOptions';
import { PinnerTabs } from '../../enums/Pinners';

const url = process.env.REACT_APP_BASE_URL;
const TOKEN = process.env.REACT_APP_MAPBOX_TOKEN;

const Pinners = () => {
  const { t, i18n } = useTranslation();
  const authToken = sessionStorage.getItem('authToken');

  const [searchQuery, setSearchQuery] = useState<string>('');
  const [searchType, setSearchType] = useState<string>(PinnerTabs.PLACE);
  const [tableData, setTableData] = useState<PinnersData[]>([]);
  const [next, setNext] = useState<string | null>(null);
  const [count, setCount] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingMore, setLoadingMore] = useState<boolean>(false);

  const forwardGeocode = async (place: string) => {
    try {
      const response = await fetch(
        `https://api.mapbox.com/geocoding/v5/mapbox.places/${encodeURIComponent(place)}.json?access_token=${TOKEN}&language=en`
      );
      const data = await response.json();
      return data.features.length > 0 ? data.features[0].place_name : place;
    } catch (error) {
      console.error('Errore nella geocodifica:', error);
      return place;
    }
  };

  const fetchData = async () => {
    try {
      setLoading(true);

      let person = undefined;
      let place = undefined;

      if (searchType === PinnerTabs.PINNER) {
        person = searchQuery.trim();
      } else if (searchType === PinnerTabs.PLACE) {
        if (searchQuery.trim()) {
          place = await forwardGeocode(searchQuery.trim());
        }
      }

      const params = {
        limit: 10,
        offset: 0,
        person: person,
        place: place,
        language: [i18n.language],
      };
      if (person) {
        params.person = person;
      }
      if (place) {
        params.place = place;
      }

      const response = await axios.get(`${url}/users/`, { params, headers: { 'Authorization': `Bearer ${authToken}` } });
      const { results, count, next } = response.data;
      setTableData(results);
      setCount(count);
      setNext(next);
    } catch (error) {
      console.error('Errore nel fetch dei dati:', error);
      setTableData([]);
      setCount(0);
      setNext(null);
    } finally {
      setLoading(false);
    }
  };


  const fetchMorePinners = async () => {
    if (!next) return;
    setLoadingMore(true);

    try {
      const response = await axios.get(next);
      const { results, count, next: newNext } = response.data;
      setTableData((prev) => [...prev, ...results]);
      setCount(count);
      setNext(newNext);
    } catch (error) {
      console.error('Errore nel fetch dei dati (load more):', error);
    } finally {
      setLoadingMore(false);
    }
  };

  const handleSearchSubmit = () => {
    fetchData();
  };

  useEffect(() => {
    fetchData();
  }, []);

  console.log(count);

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value);
  };

  const handleOpenMap = (userId: string) => {
    if (userId) {
      const url = AppRoutes.PROFILE.replace(':userId', userId);
      window.open(url, '_blank');
    } else {
      console.error('User ID not found');
    }
  };

  if (loading) {
    return <div className='w-screen h-screen flex justify-center items-center'>
      <img src={LoaderGif} alt='' className='w-16 h-auto object-cover' />
    </div>;
  }

  return (
    <div className='w-full h-auto flex flex-col justify-center items-center p-8'>
      {/* Header */}
      <div className='flex flex-col items-center mt-4 mb-2 px-4 sm:mt-6 mb-4 text-center'>
        <p className='text-primary font-bold text-sm sm:text-base'>
          Pinners
        </p>
        <Typography
          variant='h3'
          className='px-0 text-text-primary font-medium md:px-4 mt-2'
          sx={{
            fontSize: { xs: '1.3rem', sm: '2rem' }
          }}
        >
          {t('pinners:title')} <br /> {t('pinners:subTitle')}
        </Typography>
      </div>

      {/* Search bar */}
      <div className="w-full mt-4 mb-8">
        <div className="w-full flex items-center justify-center space-x-3">
          <Select
            value={searchType}
            onChange={(e) => setSearchType(e.target.value)}
            variant="outlined"
            className="w-[95px]"
            sx={{
              '& .MuiOutlinedInput-notchedOutline': {
                borderColor: '#E0E0E0',
              },
              '&:hover .MuiOutlinedInput-notchedOutline': {
                borderColor: '#E0E0E0',
              },
              '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                borderColor: '#E0E0E0',
              },
              '& .MuiSelect-select': {
                color: '#000000',
              },
            }}
          >
            <MenuItem value={PinnerTabs.PLACE}>{t('pinners:menuPlace')}</MenuItem>
            <MenuItem value={PinnerTabs.PINNER}>{t('pinners:menuPinner')}</MenuItem>
          </Select>
          <TextField
            className='w-full md:w-[30%]'
            label={PinnerTabs.PLACE === searchType ? t('pinners:searchPlace') : t('pinners:searchPinner')}
            variant="outlined"
            value={searchQuery}
            onChange={handleSearchChange}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                handleSearchSubmit();
              }
            }}
            sx={{
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: '#E0E0E0',
                },
                '&:hover fieldset': {
                  borderColor: '#E0E0E0',
                },
                '&.Mui-focused fieldset': {
                  borderColor: '#E0E0E0',
                },
              },
              '& .MuiInputLabel-root': {
                color: '#000000',
              },
              '& .MuiInputLabel-root.Mui-focused': {
                color: '#000000',
              },
              '& .MuiInputBase-input.Mui-disabled': {
                color: '#000000',
              },
            }}
          />
        </div>
        <Typography
          variant='body2'
          className='text-center mt-2 text-text-tertiary'
          sx={{
            fontSize: '0.65rem',
            marginLeft: '4.5rem'
          }}
        >
          {t('pinners:searchHelper')}
        </Typography>
      </div>

      {/* Table */}
      <div className="w-full sm:px-4 md:px-16">
        {tableData.length > 0 ? (
          <>
            {tableData.map((user) => (
              <div
                key={user.id}
                className="w-full h-auto border-b flex items-center py-4"
                style={{ borderColor: '#E0E0E0' }}
              >
                {/* Sezione sinistra comune (avatar + testo) */}
                <div className="flex justify-start items-center w-full">
                  <Avatar
                    src={user.profile_picture || 'placeholder.svg'}
                    alt={`${user.first_name} ${user.last_name}`}
                    sx={{
                      width: { xs: '3.5rem', sm: '4rem' },
                      height: { xs: '3.5rem', sm: '4rem' },
                      marginRight: '1.2rem'
                    }}
                  />
                  <div className="w-3/5 flex flex-col items-start justify-center">
                    <span className="text-text-primary font-semibold text-sm sm:text-base">
                      {user.first_name} {user.last_name}
                    </span>
                    <div className="flex items-center mt-1">
                      <Typography variant="body2" className="flex items-center mr-2">
                        <img src={TotPin} alt='totPins' className="w-8 h-8" />
                        {user.tot_pins}
                      </Typography>
                      <Typography variant="body2" className="flex items-center">
                        <img src={TotPinPro} alt='totPinsPro' className="w-8 h-8" />
                        {user.tot_pins_pro}
                      </Typography>
                    </div>
                  </div>
                </div>

                {/* Chips */}
                <div className="hidden mt-2 md:flex md:w-full md:mt-0 md:ml-4 flex space-x-2 md:justify-center md:justify-start md:items-center">
                  {user.top_trip_types?.map((typeNum, index) => {
                    const option = PIN_TYPES_OPTIONS.find(opt => opt.value === typeNum);
                    if (!option) return null;

                    return (
                      <Chip
                        key={index}
                        label={
                          <span>
                            {option.icon} {t(option.labelKey)}
                          </span>
                        }
                      />
                    );
                  })}
                </div>

                {/* Pulsante a destra (vedi mappa) */}
                <div className="mt-2 md:mt-0 md:ml-4">
                  <Button
                    variant="contained"
                    onClick={() => handleOpenMap(user.id)}
                    sx={{
                      padding: '0.3rem',
                      backgroundColor: '#E9495A',
                      '&:hover': {
                        backgroundColor: '#E6263B',
                      },
                    }}
                  >
                    <MapIcon className="w-6 h-6" />
                  </Button>
                </div>
              </div>
            ))}

            {/* carica altri */}
            {next && (
              <div className="w-full flex justify-center items-center mt-8 mb-4">
                <LoadingButton
                  loading={loadingMore}
                  onClick={fetchMorePinners}
                  sx={{
                    width: { xs: '45%', sm: '15%' },
                    border: '1px solid #E0E0E0',
                    color: '#606060',
                    textTransform: 'none',
                    '&:hover': {
                      borderColor: '#606060',
                      backgroundColor: 'transparent'
                    }
                  }}
                >
                  {t('pinners:loadMore')}
                </LoadingButton>
              </div>
            )}
          </>
        ) : (
          <div className='w-full h-[40vh] my-4 flex flex-col justify-center items-center gap-4'>
            <Typography variant="body1" className="text-text-primary">
              {t('pinners:noResults')}
            </Typography>
            <EmptyState className='' />
          </div>
        )}
      </div>
    </div>
  );
};

export default Pinners;
