import Logo from '../../assets/images/logo-white.png'

const Policy = () => {
  return (
    <div className='w-full h-auto bg-primary shadow-2xl flex justify-center items-center gap-2 p-4 realtive'>
      <img src={Logo} alt='Logo' className='w-[12rem] h-auto' />
    </div>
  );
};

export default Policy;