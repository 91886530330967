import Logo from '../../assets/images/logo-white.png'

const Policy = () => {
  return (
    <>
      <div className='w-full h-auto bg-primary shadow-2xl flex justify-center items-center gap-2 p-4 realtive'>
        <img src={Logo} alt='Logo' className='w-[12rem] h-auto' />
      </div>

      <iframe
        src="/policy.html"
        title="Privacy Policy"
        style={{
          width: '100%',
          height: '100vh',
          border: 'none',
          display: 'block',
          padding: 0,
          margin: 0
        }}
      />
    </>
  );
};

export default Policy;